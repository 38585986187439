import http from '@/utils/http';

// 获取赛事列表
export const getUniqueTournament = (params) => {
    return http.request({
        url: '/v1/ballsports/base/unique_tournament/list',
        method: 'get',
        params: params
    })
}

// 获取赛事分类列表
export const getUniqueTournamentCategory = (params) => {
    return http.request({
        url: '/v1/ballsports/base/unique_tournament/category/list',
        method: 'get',
        params: params
    })
}

// 新增联赛的关联
export const addUniqueTournamentRelation = (params) => {
    return http.request({
        url: '/v1/ballsports/base/unique_tournament/relation/add',
        method: 'post',
        data: params
    })
}

// 删除联赛的关联
export const delUniqueTournamentRelation = (params) => {
    return http.request({
        url: '/v1/ballsports/base/unique_tournament/relation/del',
        method: 'post',
        data: params
    })
}

// 获取联赛的所有源
export const getUniqueTournamentSources = (params) => {
    return http.request({
        url: '/v1/ballsports/base/unique_tournament/source/list',
        method: 'get',
        params: params
    })
}

// 搜索
export const searchUniqueTournament = (params) => {
    return http.request({
        url: '/v1/ballsports/base/unique_tournament/search',
        method: 'post',
        data: params
    })
}

// 更新比赛
export const updateUniqueTournament = (params) => {
    return http.request({
        url: "/v1/ballsports/base/unique_tournament/update",
        method: 'post',
        data: params,
    })
}