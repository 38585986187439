<template>
    <div>
        <teamHonorList :sport_id="sport_id"></teamHonorList>
    </div>
</template>

<script>

import teamHonorList from '@/components/ballsports/team_honor/list.vue';

export default {
    components: {
        teamHonorList
    },
    data () {
        let self = (window.x = this)
        return {
            sport_id: 1,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>