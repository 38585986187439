<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
        :transfer="false"
    >
        <teamHonorEdit :sport_id="sport_id" :team_honor_id="edit_id" @closePage="closeEditPage"></teamHonorEdit>
    </Drawer> 

    <!-- <Modal v-model="modal_edit_team" draggable title="编辑战队" footer-hide >
        <div slot="header" style="color:#f60;text-align:center; ">
            <span>编辑队伍</span>
        </div>
        <div class="modal-div-containter">
            <teamEdit :sport_id="sport_id" :team_id="modal_edit_team_id" @closePage="closeEditModalTeam"></teamEdit>
        </div>
    </Modal> -->

    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="20" style="text-align: right">
            <Button type="success" @click="openEdit" >新增</Button>
        </Col>
    </Row>
    <fitTable
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getTeamHonor, } from '@/api/ballsports/base/team_honor';
import teamHonorEdit from './edit.vue';
import fitTable from '../../basic/table.vue';
import teamEdit from '../team/edit.vue';

// const delButton = (vm, h, params) => {
//     return h(
//         'Poptip',
//         {
//             props: {
//                 confirm: true,
//                 title: '您确定要删除这条数据吗?',
//                 transfer: true
//             },
//             on: {
//                 'on-ok': () => {
//                     // vm.delete(params.row.id)
//                     // 删除本行
//                     vm.data.splice(params.index, 1)
//                 }
//             }
//         },
//         [
//         h(
//             'Button',
//             {
//                 style: {
//                     margin: '2px 2px'
//                 },
//                 props: {
//                     type: 'warning',
//                     size: 'small'
//                 }
//             },
//             '删除'
//         )
//         ]
//     )
// }

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "player-honor-list",
    props: {
        sport_id: Number,   // 运动id
    },
    components: {
        // thirdPlayerHonotSearch,
        // team_honorEdit,
        teamHonorEdit,
        fitTable,
        teamEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_edit: false,
            edit_id: 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            modal_add_relation: false,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    width:100,
                },
                {
                    title: '赛事',
                    align: 'left',
                    key: 'unique_tournament_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let unique_tournament = currentRow.unique_tournament 
                        return h(
                            'Row', unique_tournament.id>0 ? [
                                h("Col", 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                if (! self.$unit.filterGameids( [1,2], self.sport_id ) ){
                                                    // self.editUniqueTournament(unique_tournament.id);
                                                }
                                            }
                                        } },  unique_tournament.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                if (! self.$unit.filterGameids( [1,2], self.sport_id ) ){
                                                    // self.editUniqueTournament(unique_tournament.id);
                                                }
                                            }
                                        } }, unique_tournament.name,  ),
                                    ]
                                ,),
                            ] : ''
                        )
                    }
                },
                {
                    title: '赛季',
                    align: 'left',
                    key: 'season_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        var season = currentRow.season_id;
                        return season.id > 0? [
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    // self.editSeason(season.id);
                                }
                            } },  season.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    // self.editSeason(season.id);
                                }
                            } }, season.name,  )
                        ]: ''
                    }
                },
                {
                    title: '荣誉年份',
                    align: 'left',
                    key: 'season',
                    minWidth: 80,
                },
                {
                    title: '荣誉',
                    align: 'left',
                    key: 'honor',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let honor = currentRow.honor 
                        return h(
                            'Row', honor.id>0 ? [
                                h("Col", 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                if (! self.$unit.filterGameids( [1,2], self.sport_id ) ){
                                                    // self.editUniqueTournament(unique_tournament.id);
                                                }
                                            }
                                        } },  honor.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                if (! self.$unit.filterGameids( [1,2], self.sport_id ) ){
                                                    // self.editUniqueTournament(unique_tournament.id);
                                                }
                                            }
                                        } }, honor.name,  ),
                                    ]
                                ,),
                            ] : ''
                        )
                    }
                },
                {
                    title: '球队',
                    align: 'left',
                    key: 'team',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let team = currentRow.team 
                        return h(
                            'Row', team.id>0 ? [
                                h("Col", 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                if (! self.$unit.filterGameids( [1,2], self.sport_id ) ){
                                                    // self.editUniqueTournament(unique_tournament.id);
                                                }
                                            }
                                        } },  team.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                if (! self.$unit.filterGameids( [1,2], self.sport_id ) ){
                                                    // self.editUniqueTournament(unique_tournament.id);
                                                }
                                            }
                                        } }, team.name,  ),
                                    ]
                                ,),
                            ] : ''
                        )
                    }
                },
                
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton(self, h, params), ]// delButton(self, h, params) ]
                    }
                }
            ],
            data: [],
            modal_edit_team: false,
            modal_edit_team_id: 0,
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        getData () {
            let self = this;
            this.loading = true;
            // 搜索日期
            let lang = sessionStorage.getItem("lang");
            // console.log("lang: ", lang);
            var params = {
                sport_id: self.sport_id,
                search_key: self.search_key,
                page: self.current_page,
                lang: lang,
            }
            getTeamHonor(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        
        // delete (_id) {
        //     var params = {
        //         id: _id,
        //         type: this.data_type
        //     }
        //     deleteData(params).then(response => {
        //         if (response.data.code == 0) {
        //             return true
        //         } else {
        //             this.$Message.error(response.data.msg)
        //             return false
        //         }
        //     })
        // },
        // end 
        openEdit(){
            let self = this;
            self.edit_id=0;
            self.drawer_edit = true;
        },
        // end
        closeEditPage(){
            let self = this;
            self.drawer_edit = false;
        },
        // end
        editTeam(_id){
            let self = this;
            self.modal_edit_team_id = _id;
            self.modal_edit_team = true;
        },
        // end
        closeEditModalTeam(){
            let self = this;
            self.modal_edit_team = false;
            self.modal_edit_team_id = 0;
        },
        // end
    },
    mounted () {
        // console.log(this.sport_id)
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>